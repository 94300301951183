@media (min-width: 1780px) {
	.return-to-top {
		bottom: 100px;
		right: 100px;
	}
}

@media (max-width: @screen-md-max) {
	nav.section-header {
		.header-flexbox {
			.search-engine-wrapper {
				width: 100% - 180px;
			}
		}
	}

	div.section-main-carousel {
		.owl-carousel-slider {
			.owl-controls {
				.owl-nav {
					max-width: 940px;
				}
			}
		}
	}

	div.kategoria-section-content {
		.content-flexbox {
			.flexbox-sidebar {
				width: 220px;
			}

			.flexbox-body {
				width: 670px;
			}
		}
	}

	.payments-credits {
		.credits-service {
			flex-wrap: wrap;
			justify-content: center;
			gap: 15px 5px;

			p {
				width: 100%;
				text-align: center;
			}
		}
	}

	div.section-footer {
		.footer-flexbox {
			flex-wrap: wrap;
			justify-content: flex-start;

			.flexbox-service {
				width: calc(100% / 3);

				&.newsletter {
					.text {
						max-width: 100%;
					}
				}

				&:nth-child(4), &:nth-child(5) {
					margin-top: 30px;
				}
			}
		}

	}
}

@media (max-width: @screen-sm-max) {
	nav.section-header {
		overflow: hidden;

		.container {
			padding-right: 0;
		}

		.header-flexbox {
			flex-wrap: wrap;

			.nav-button {
				display: flex;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
					top: 10px;

					button {
						margin-right: 25px;
					}
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					overflow: hidden;
					min-height: 100%;
					padding: 20px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;

						.nav-logo {
							display: block;

							a {
								display: block;
								padding: 0;
								text-align: center;

								svg {
									height: 22px;
									fill: #232323;
									transition: all 0.3s;

									&:hover {
										fill: @color1;
									}
								}
							}
						}

						li {
							width: 100%;

							a {
								background: none;
								color: #232323;
							}

							&:hover {
								a {
									&:after {
										content: none;
									}
								}
							}

							&.active {
								a {
									background: none;
									color: @color2;
								}
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.search-engine-wrapper {
				position: relative;
				height: 0 !important;
				right: 0;
				width: 100%;
				top: auto;
				min-height: 0 !important;
				border-bottom: none;

				input {
					padding-left: 0;
				}

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 10000px;
					background: #232323;
					transform: translateX(-100%);
				}

				&:after {
					content: '';
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					width: 10000px;
					background: #fff;
					transform: translateX(100%);
				}

				&.active {
					min-height: 60px !important;
					height: 60px !important;
				}
			}
		}

		.visibility-hidden {
			opacity: 1;
		}
	}

	div.section-main-carousel {
		margin-bottom: 20px;

		.item-flexbox {
			.item-description {
				padding-left: 30px;

				h1, h2 {
					font-size: 35px;
					line-height: 33px;
					padding-right: 50px;

					br {
						content: '';

						&:after {
							content: ' ';
						}
					}
				}

				p {
					padding-right: 70px;
				}

				.btn {
					margin-top: 20px;
				}
			}
		}

		.owl-carousel-slider {
			.owl-controls {
				.owl-nav {
					max-width: 720px;
				}
			}
		}
	}

	div.kategoria-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-sidebar {
				width: 100%;

				.nav-button {
					display: block;
				}

				.category-nav-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.nav-button-close {
						display: block;
					}

					.category-nav-inner {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						min-height: 100%;
						padding: 50px;
						margin-right: 0;

						.category-nav {
							flex-direction: column;

							li {
								&.child {
									ul {
										li {
											a {
												justify-content: center;
											}
										}
									}
								}
							}
						}

						.filter-nav {
							ul {
								li {
									.checkbox-wrapper {
										.radio-box {
											justify-content: center;
										}
									}
								}
							}

							.inputs-wrapper {
								justify-content: center;

								input {
									max-width: 100px;
								}
							}

							.buttons-wrapper {
								justify-content: center;

								> * {
									margin-right: 20px;

									&:last-child {
										margin-right: 0;
									}
								}
							}

							.category-description {
								max-width: 500px;
							}

							> * {
								border-top: none;
							}
						}
					}

					&.active {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.flexbox-body {
				width: 100%;
			}
		}

		&.wyszukiwanie {
			.content-flexbox {
				.flexbox-body {
					.products-flexbox {
						.product-service {
							width: 30%;
						}
					}
				}
			}
		}
	}

	div.panel-klienta-koszyk-section-content {
		.heading-wrapper {
			flex-direction: column;
			align-items: flex-start
		}

		.free-delivery-wrapper {
			margin-top: 20px;
			min-width: 100%;

			.wrapper-inner {
				justify-content: flex-center;
			}
		}
	}

	div.section-footer {
		.footer-flexbox {
			.flexbox-service {
				width: calc(100% / 2);

				&:nth-child(3) {
					margin-top: 30px;
				}

				&:nth-child(5) {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	body {
		padding-top: 0;

		.btn-default {
			&.btn-empty {
				padding: 0 !important;
				color: #414141;
				background: none;
			}

			&.btn-big {
				font-size: 12px;
				padding: 12px 17px;
			}
		}
	}

	nav.section-header {
		position: relative;
	}

	div.section-main-carousel {
		display: none;
	}

	div.section-offer {
		margin-top: 50px;

		.owl-carousel-wrapper {
			padding: 0 25px;
			position: relative;

			.owl-carousel {
				.owl-controls {
					.owl-nav {
						.owl-prev {
							margin-left: -30px;

							&:before {
								width: 25px;
								height: 25px;
							}
						}
			
						.owl-next {
							margin-right: -30px;

							&:before {
								width: 25px;
								height: 25px;
							}
						}
					}
				}
			}
		}

		.products-flexbox {
			&.center {
				.product-service-wrapper {
					width: calc(100% / 3);
				}
			}
		}

		&.promotion {
			margin-top: 0;
		}
	}

	div.kategoria-section-content {
		.content-flexbox {
			.flexbox-body {
				.products-flexbox {
					.product-service {
						width: 30%;
					}
				}
			}
		}
	}

	div.produkt-section-content {
		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-sidebar {
				margin-top: 25px;
				width: 100%;
				max-width: 420px;
			}

			.flexbox-body {
				margin-top: 25px;
				width: 100%;

				.panel-group {
					margin-top: 35px;
				}
			}
		}
	}

	div.static-page-section-content {
		.content-flexbox {
			.flexbox-description {
				width: 100%;
			}

			.flexbox-image {
				display: none;
			}
		}
	}

	div.section-footer-wrapper {
		.background-cover {
			background-size: 40%;
		}
	}

	div.section-footer {
		padding-top: 50px;

		.footer-flexbox {
			display: flex;
			justify-content: space-between;

			.flexbox-service {
				.heading {
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					color: #7a7a7a;
					margin-bottom: 10px;
				}
			}
		}

		.footer-credits {
			.credits-flexbox {
				flex-direction: column;
				align-items: center;
				text-align: center;
			}
		}
	}

	.cookies-wrapper {
		background: rgba(0, 0, 0, 0.8);

		.cookies-service {
			.service-inner {
				background: none;
				padding: 0;
			}
		}
	}

	div.panel-klienta-logowanie-section-content {
		padding: 0 0 50px 0;

		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				margin-top: 50px;
				width: 100%;
				max-width: 360px;
				border-right: none;

				img {
					max-width: 150px;
				}

				.heading {
					margin-top: 30px;
				}
			}
		}

		.password-wrapper {
			margin-top: 50px;

			img {
				max-width: 200px;
			}
		}
	}

	div.panel-klienta-section-content {
		padding: 50px 0;

		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				margin-top: 50px;
				width: 100%;
				border: none;

				img {
					width: 100%;
				}

				.heading {
					margin-top: 30px;
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}

		.order-info {
			flex-direction: column;
			align-items: flex-start;

			.info-description {
				margin-bottom: 20px;
			}
		}
	}

	div.panel-klienta-koszyk-section-content {
		.heading {
			text-align: center;
		}

		.confirm-buttons {
			margin-top: 10px;
			flex-direction: column;
			align-items: center;

			> * {
				margin-top: 20px;
			}
		}

		.login-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				border-left: none;

				input {
					margin: 10px auto 0 auto;
					background: #e0e1dc;
					border: none;
					display: block;
					height: 40px;
					width: 250px;
					padding: 10px;
					outline: 0;
					text-align: center;
				}

				input::placeholder {
					text-transform: uppercase;
					color: #414141;
					font-size: 13px;
					text-align: center;
				}

				.btn {
					margin-top: 20px;
				}

				.btn-light {
					margin-bottom: 50px;
				}

				.btn-link {
					margin-top: 20px;
					font-size: 13px;
					color: #414141;
					display: block;
				}

				&:first-child {
					margin-bottom: 50px;
				}
			}
		}
	}

	.steps-flexbox {
		.flexbox-service {
			padding: 5px 12px;

			.name {
				font-size: 14px;
			}

			.text {
				font-size: 12px;
			}
		}
	}

	.address-flexbox {
		flex-direction: column;
		text-align: center;

		.flexbox-service {
			width: 100%;
			border: none;

			&:nth-child(1) {
				padding-right: 0;
			}

			&:nth-child(2) {
				margin-top: 50px;
				padding-left: 0;
			}
		}
	}

	.inpost-select {
		input {
			height: 42px;
		}
	}

	.price-summary {
		text-align: center;
		flex-direction: column;

		.summary-inner {
			width: 100%;

			&:nth-child(2) {
				text-align: center;
			}
		}

		.summary-code {
			.code-inner {
				justify-content: center;

				input {
					height: 42px;
				}

				.inner-value {
					height: 42px;
				}
			}

			.code-value {
				justify-content: center;
				flex-direction: column;

				p {
					&:nth-child(2) {
						margin-top: 3px;
						margin-left: 0;
					}
				}

				button {
					margin-left: 0;
					margin-top: 5px;
				}
			}
		}
	}

	.table-responsive {
		.table {
			display: block;

			tbody {
				width: 100%;
				display: flex;
				flex-direction: column;
			} 

			tr {
				margin-top: 20px;
				padding-top: 20px;
				border-top: 1px solid #e0e1dc;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;

				&:nth-child(1) {
					display: none;
				}

				&:nth-child(2) {
					margin-top: 0;
					padding-top: 0;
					border: none;
				}

				td {
					margin-top: 15px;
					display: block;
					border: none;
					padding: 0;
					width: 100%;

					&:nth-child(1) {
						width: auto;
						margin-top: 0;
						margin-right: 30px;
						max-width: 85%;
						order: 1;
					}

					&:nth-child(2) {
						margin-top: 0;
						width: auto;
						order: 2;
					}

					&:nth-child(3) {
						order: 4;
					}

					&:nth-child(4) {
						order: 3;
					}

					&:nth-child(5) {
						order: 5;
					}
				}
			}
		}

		&.orders {
			.table {
				tr {
					td {
						padding: 0;
					}
				}
			}
		}
	}

	div.panel-klienta-koszyk-podsumowanie-section-content {
		.content-description {
			img {
				max-width: 220px;
			}
		}
	}
}

@media (max-width: 600px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-logo {
				.sygnet {
					display: none;
				}

				.logo {
					height: 45px;
					padding-right: 0;
					padding-left: 0;
				}
			}

			.flexbox-account {
				li {
					a, button {
						height: 45px;
						width: 45px;

						svg {
							&.icon-desktop {
								display: none;
							}

							&.icon-mobile {
								display: block;
							}

							&.icon-size-1 {
								height: 20px;
							}

							&.icon-size-2 {
								height: 22px;
							}
						}

						.quantity {
							bottom: auto;
							right: auto;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							padding-top: 4px;
							font-size: 10px;
							font-weight: 500;
							padding-left: 0;
							background: none;
							height: auto;
							width: auto;
							border-radius: 0;
							color: #fff;
						}
					}

					&.active {
						a {
							.quantity {
								color: #232323;
							}

							&.white {
								.quantity {
									color: #fff;
								}
							}
						}
					}
				}
			}

			.search-engine-wrapper {
				input {
					font-size: 13px;
				}

				input::placeholder {
					font-size: 13px;
				}

				.search-engine-btn-close {
					min-width: 45px;
					height: 45px;

					img {
						height: 15px !important;
					}
				}

				&.active {
					min-height: 45px !important;
					height: 45px !important;
				}
			}

			.nav-button {
				height: 45px;
				width: 45px;
				
				.button-bar {
					width: 19px;
				}
			}
		}
	}

	div.kategoria-section-content {
		.content-flexbox {
			.flexbox-body {
				.sorting-flexbox-wrapper {
					flex-direction: column-reverse;
					align-items: flex-start;

					.sorting-flexbox {
						margin-top: 10px;
						flex-wrap: wrap;

						li {
							p {
								font-size: 12px;
							}
							
							a {
								font-size: 12px;
							}
						}
					}

					.grid-flexbox {
						margin-top: 10px;


						display: flex;
						align-items: center;
						
						li {
							margin-right: 10px;
							opacity: 0.5;

							a {
								img {
									height: 14px;
								}
							}

							&.active {
								opacity: 1;
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

				.pagination-flexbox {
					.score {
						p {
							font-size: 12px;

							img {
								height: 10px;
								margin-right: 4px;
							}
						}
					}

					ul {
						li {
							a {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}

	div.produkt-section-content {
		.content-flexbox {
			.flexbox-sidebar {
				.owl-carousel-slider {
					.owl-controls {
						.owl-nav {
							display: none;
						}
					}
				}
			}
		}
	}

	div.kontakt-section-content {
		.google-map {
			&.full-height {
				margin-top: 0;
				height: 100vh - 45px;
			}
		}
	}

	div.section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.collapse-wrapper {
				margin-top: 30px;
				display: block;

				.btn-mobile {
					background: #a6a6a6;
					padding: 10px;
					border-radius: 6px;
					margin: 0 auto;
					display: flex;
					align-items: center;
					border: none;
					outline: none;
					transition: all 0.3s;

					&.btn-hidden {
						visibility: hidden;
						opacity: 0;
						margin-top: -20px;
					}

					span {
						height: 5px;
						width: 5px;
						border-radius: 50%;
						background: #414141;

						& + span {
							margin-left: 5px;
						}
					}
				}
			}

			.flexbox-service {
				width: 100%;
				margin-top: 30px;

				&.desktop {
					display: none;
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.products-flexbox {
		.product-service {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			border: 1px solid #fff;
			background: #fff;
			transition: all 0.3s;

			header {
				width: 100%;
			}

			.service-image {
				img {
					width: 100%;
				}
			}

			.service-description {
				display: none;
			}

			.name{
				margin-top: 10px;
				padding: 0 5px;
				font-weight: 700;
				color: #414141;
			}

			.name-list {
				font-size: 20px;
				font-weight: 400;
				color: #414141;
				display: none;
			}

			.price {
				margin-bottom: 2px;
				padding: 0 5px;
				font-weight: 300;
				color: #414141;
				white-space: nowrap;

				span {
					margin-left: 4px;
					text-decoration: line-through;
				}
			}

			.type {
				margin-bottom: 10px;
				padding: 0 5px;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 12px;
				color: #414141;
			}

			.btn-more {
				display: none;
			}

			&:hover {
				border-color: @color1;
				background: @color1;
			}

			&.service-hidden {
				padding: 0 !important;
				margin: 0 !important;
				border: none !important;
				visibility: hidden !important;
			}
		}

		&.grid-list {
			padding-bottom: 20px;

			.product-service {
				width: 47% !important;
				padding: 0;
				flex-direction: column;
				text-align: center;
				margin-top: 20px;
				position: relative;

				&:before {
					content: none;
				}

				header {
					width: auto;
				}

				.service-name {
					width: auto;
				}

				.service-description {
					display: none;
				}


				.info-name {
					width: auto;
				}

				.name {
					display: block;
				}

				.name-list {
					display: none;
				}

				.price {
					margin-bottom: 2px;
					padding: 0 5px;
					font-weight: 300;
					font-size: 14px;
					color: #414141;
					white-space: nowrap;

					span {
						margin-left: 4px;
						text-decoration: line-through;
					}
				}

				.type {
					margin-bottom: 10px;
					padding: 0 5px;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 12px;
					color: #414141;
				}

				.btn-more {
					display: none;
				}

				&:hover {
					border-color: @color1;
					background: @color1;
				}
			}
		}
	}

	div.section-offer {
		.offer-flexbox-helper {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.products-flexbox {
				width: 100%;
				padding: 0;

				.products-flexbox {
					width: 46%;
					margin: 2%;
				}
			}
		}

		.owl-carousel-wrapper {
			display: none;
		}

		.products-flexbox {
			&.center {
				.product-service-wrapper {
					width: 46%;
					margin: 2%;
				}
			}

			&.hidden-desktop {
				display: flex;
			}
		}

		&.promotion {
			.product-service-wrapper {
				&:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
					display: none;
				}
			}
		}
	}

	div.kategoria-section-content {
		.content-flexbox {
			.flexbox-body {
				margin-top: 30px;

				.heading {
					text-align: center;
				}

				.sorting-flexbox-wrapper {
					align-items: center;
					padding-bottom: 30px;

					.sorting-flexbox {
						justify-content: center;

						li {
							text-align: center;
							padding: 0;
							margin: 2px 4px;

							&.divider {
								display: none;
							}

							&:first-child {
								width: 100%;
								opacity: 0.5;
								margin: 0 0 7px 0;
							}
						}
					}

					.grid-flexbox {
						display: none;
					}
				}

				.products-flexbox {
					.product-service {
						width: 47%;
					}
				}

				.pagination-flexbox {
					flex-direction: column;

					ul {
						margin-top: 40px;
						justify-content: space-between;
						width: 100%;

						li {
							margin: 0;
							padding: 0;
							border: none;
						}
					}
				}
			}
		}
	}

	div.produkt-section-content {
		.container {
			padding: 0;
		}

		.content-flexbox {
			.flexbox-sidebar {
				margin-top: 0;
				padding-bottom: 0;

				.owl-carousel-slider {
					.owl-controls {
						z-index: 9999;
						height: auto;
						top: auto;
						bottom: 10px;

						.owl-nav {
							display: none;
						}

						.owl-dots {
							position: relative;
							bottom: auto;
						}
					}
				}
			}

			.flexbox-body {
				padding: 0 12px;
				margin-top: 15px;

				.price {
					margin-top: 5px;
					margin-bottom: 0;
				}

				.description {
					margin: 10px 0 20px 0;
					padding: 10px 0 20px 0;
				}
			}
		}
	}

	div.panel-klienta-koszyk-section-content {
		.free-delivery-wrapper {
			&:not(.success){
				.wrapper-inner {
					.inner-icon {
						display: none;
					}

					.inner-heading {
						margin-left: 0;
					}
				}
			}
		}
	}

	.payments-credits {
		.credits-service {
			.service-box {
				& + .service-box {
					margin-left: 0;
					margin-top: 15px;
				}
			}
		}
	}
}

@media (max-width: 400px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-logo {
				.sygnet {
					height: 45px;
					width: 45px;

					svg {
						height: 24px;
					}
				}
			}

			.flexbox-account {
				li {
					position: relative;

					a, button {
						height: 45px;
						width: 35px;

						img {
							height: 24px !important;
						}

						.quantity {
							bottom: 3px;
							right: 3px;
							height: 18px;
							width: 18px;
							font-size: 8px;
						}
					}
				}
			}

			.search-engine-wrapper {
				.search-engine-btn-close {
					min-width: 45px;
					height: 45px;

					img {
						height: 15px !important;
					}
				}

				&.active {
					min-height: 45px !important;
					height: 45px !important;
				}
			}

			.nav-button {
				height: 45px;
				width: 45px;

				.button-bar {
					width: 22px;
				}

				&:hover, &:focus {
					.button-bar {
						width: 25px;
					}
				}
			}
		}
	}

	div.section-footer {
		.footer-credits {
			.credits-flexbox {
				.flexbox-inner {
					flex-direction: column;
					text-align: center;

					> * + * {
						margin-left: 0;
					}
				}
			}
		}
	}
}