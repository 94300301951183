@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700,900&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700,800&display=swap');

@font-face {
	font-family: 'gilroyextrabold';
	src: url('../fonts/gilroy-extrabold-webfont.woff2') format('woff2'), url('../fonts/gilroy-extrabold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'gilroysemibold';
	src: url('../fonts/gilroy-semibold-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'gilroylight';
	src: url('../fonts/gilroy-light-webfont.woff2') format('woff2'), url('../fonts/gilroy-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'gilroymedium';
	src: url('../fonts/radomir_tinkov_-_gilroy-medium-webfont.woff2') format('woff2'), url('../fonts/radomir_tinkov_-_gilroy-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body {
	min-height: 100vh;
	font-family: 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 60px;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}

	.btn-default {
		border-radius: 0;
		border: none;
		font-size: 11px;
		color: #fff;
		font-family: 'gilroysemibold';
		text-transform: uppercase;
		background: #767676;
		transition: all 0.3s;

		&:hover {
			background: #5e5e5e;
			color: #fff;
		}

		&.btn-yellow {
			background: @color2;
			color: #414141;

			&:hover {
				background: darken(@color2, 10%);
			}
		}

		&.btn-light {
			background: #e0e1dc;
			color: #414141;

			&:hover {
				background: darken(#e0e1dc, 10%);
			}
		}

		&.btn-white {
			background: transparent;
			color: #fff;
			border: 1px solid #fff;

			&:hover {
				color: #333;
				background: #fff;
			}
		}

		&.btn-bg-white {
			background: #fff;
			color: #232323;
			border: 1px solid #fff;

			&:hover {
				color: #232323;
			}
		}

		&.btn-big {
			font-size: 15px;
			padding: 14px 20px;
		}
	}

	.ck-editor-text {
		ul, ol {
			padding-left: 20px;
		}
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

.return-to-top {
	position: fixed;
	z-index: 333;
	bottom: 15px;
	right: 15px;
	transition: all 0.3s;
	border-radius: 3px;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.4s;
	visibility: hidden;
	opacity: 0;

	img, svg {
		height: 100%;
		width: 100%;
		transform: rotate(-90deg);
	}

	svg {
		transform: rotate(-90deg);
		fill: #fff;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 2222;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			max-width: 430px;
			width: 100%;
			height: 100%;
			padding: 50px 40px 0 40px;
			position: relative;

			.service-button {
				position: absolute;
				top: 11px;
				right: 7px;
				border: none;
				background: none;
				padding: 10px;
				outline: none;

				.button-inner {
					position: relative;
					
					.button-bar {
						display: block;
						width: 19px;
						transition: all 0.2s;
						height: 2px;
						background: #000;

						&:nth-child(1) {
							transform: rotate(45deg) translateY(2.5px) translateX(2.5px);
						}

						&:nth-child(2) {
							opacity: 0;
						}

						&:nth-child(3) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.inner-content {
				text-align: center;

				.heading {
					color: #414141;
					font-size: 20px;
					font-weight: 400;
				}

				img {
					margin-top: 20px;
					width: 100%;
				}

				.name {
					margin-top: 20px;
					font-size: 23px;
					color: #313131;
					font-family: 'gilroymedium';
				}

				.info {
					font-size: 13px;
					font-weight: 300;
					color: #414141;
					text-transform: uppercase;
				}

				.buttons-wrapper {
					margin-top: 20px;

					.btn-cancel {
						display: inline-block;
						padding: 20px;
						font-size: 13px;
						color: #767676;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

.system-alerts {
	width: 100%;
	text-align: center;

	.alert {
		border-radius: 0;
		border: none;
		margin: 0;
		min-height: 60px;
		padding: 10px 0;
		display: flex;
		align-items: center;

		.alert-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-hidden {
				visibility: hidden;
				opacity: 0;
				height: 14px;
			}

			p {
				color: #fff;
				padding: 0 50px;
				font-size: 15px;
			}

			button {
				color: #fff;
				opacity: 1;
				font-weight: 300;
				float: none;

				img {
					height: 14px;
				}
			}
		}

		&.alert-success {
			background: #414141;
		}

		&.alert-info {
			background: #414141;
		}

		&.alert-warning {
			background: #414141;
		}
	}
}

.products-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.product-service {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		border: 1px solid #fff;
		background: #fff;
		transition: all 0.3s;

		header {
			width: 100%;
		}

		.service-image {
			position: relative;
			overflow: hidden;

			img {
				width: 100%;
				transition: all 0.3s;

				&:nth-child(2) {
					top: 0;
					left: 0;
					transform: translateY(-101%) !important;
					position: absolute;
				}
			}
		}

		.service-description {
			display: none;
		}

		.name{
			margin-top: 10px;
			padding: 0 5px;
			font-weight: 700;
			color: #414141;
		}

		.name-list {
			font-size: 20px;
			font-weight: 400;
			color: #414141;
			display: none;
		}

		.price {
			margin-bottom: 2px;
			padding: 0 5px;
			font-weight: 300;
			color: #414141;
			white-space: nowrap;

			span {
				margin-left: 4px;
				text-decoration: line-through;
			}
		}

		.type {
			margin-bottom: 10px;
			padding: 0 5px;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 12px;
			color: #414141;
		}

		.btn-more {
			display: none;
		}

		&:hover {
			border-color: @color1;
			background: @color1;

			.service-image {
				img {
					&:nth-child(1) {
						transform: translateY(101%) !important;
					}

					&:nth-child(2) {
						transform: translateY(0) !important;
					}
				}
			}
		}

		&.service-hidden {
			padding: 0 !important;
			margin: 0 !important;
			border: none !important;
			visibility: hidden !important;
		}
	}

	&.grid-list {
		padding-bottom: 20px;

		.product-service {
			width: 100% !important;
			padding: 10px;
			flex-direction: row;
			text-align: left;
			margin-top: 40px;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 1px;
				left: 0;
				top: -20px;
				background: #e0e1dc;
			}

			&:first-child {
				margin-top: 20px;

				&:before {
					content: none;
				}
			}

			header{
				width: 180px;
			}

			.service-name {
				width: 230px;
			}

			.service-description {
				display: block;
				width: 350px;

				p {
					color: #414141;
				}
			}


			.info-name {
				width: 100%;
			}

			.name {
				display: none;
			}

			.name-list {
				display: block;
			}

			.price {
				padding: 0;
				font-size: 20px;
				font-weight: 300;
			}

			.type {
				padding: 0;
				margin-bottom: 0;
			}

			.btn-more {
				margin-top: 20px;
				border: 1px solid #e0e1dc;
				background: none;
				font-size: 15px;
				font-family: 'gilroysemibold';
				color: #232323;
				padding: 14px 20px;
				text-transform: uppercase;
				display: block;
				transition: all 0.3s;

				&:hover {
					border-color: darken(@color2, 10%) !important;
					background: darken(@color2, 10%) !important;
				}
			}

			&:hover {
				background: none;
				border-color: @color2;

				.btn-more {
					border-color: @color2;
					background: @color2;
				}
			}
		}
	}
}

section.section-alert {
	position: relative;
	text-align: center;
	display: none;

	.alert {
		z-index: 999;
		background: #414141;
		padding: 14.5px 0;
		border-radius: 0;
		margin: 0;
		border: none;

		.alert-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-hidden {
				visibility: hidden;
				opacity: 0;
				height: 14px;
			}

			p {
				font-size: 11px;
				font-weight: 500;
				color: #fff;
				padding: 0 20px;

				a {
					color: inherit;
					font-weight: 800;
					text-decoration: underline !important;
				}
			}

			button {
				color: #fff;
				opacity: 1;
				font-weight: 300;
				float: none;

				img {
					height: 14px;
				}
			}
		}
	}
}

nav.section-header {
	background: #232323;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;

	.header-flexbox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.flexbox-nav-wrapper-out {
			display: flex;
			align-items: center;
		}

		.flexbox-logo {
			display: flex;
			align-items: center;
			transition: opacity 0.5s;

			.sygnet {
				height: 43px;
				width: 43px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s;

				svg {
					height: 23px;
					transform: translateX(-1px);

					path {
						transition: all 0.3s;
						fill: #232323;
					}
				}

				&:hover {
					background: @color2;
				}
			}

			.logo {
				height: 60px;
				padding: 0 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s;

				svg {
					height: 13px;

					path {
						transition: all 0.3s;
						fill: #fff;
					}
				}

				&:hover {
					svg {
						path {
							fill: @color2;
						}
					}
				}
			}

			&.active {
				.logo {
					svg {
						path {
							fill: @color2;
						}
					}
				}
			}
		}

		.flexbox-nav-wrapper {
			transition: opacity 0.5s;

			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before, &:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					margin-right: 7px;
					display: flex;
					justify-content: center;
					align-items: center;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 3px;
						background: #222;
						border-radius: 5px;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				margin-left: 10px;

				.flexbox-nav {
					display: flex;
					align-items: center;

					.nav-logo {
						display: none;
					}

					li {
						a {
							height: 60px;
							padding: 0 15px;
							font-family: 'gilroymedium';
							text-transform: uppercase;
							background: #232323;
							color: #fff;
							font-size: 14px;
							display: flex;
							align-items: center;
							justify-content: center;
							position: relative;
							transition: all 0.3s;

							&:after {
								content: '';
								position: absolute;
								bottom: 0;
								height: 6px;
								width: 100%;
								background: #565656;
								opacity: 0;
								transition: all 0.3s;
							}
						}

						&:hover {
							a {
								&:after {
									opacity: 1;
								}
							}
						}

						&.active {
							a {
								background: @color2;
								color: #232323;

								&:after {
									opacity: 0;
								}
							}
						}
					}
				}
			}
		}

		.flexbox-account {
			display: flex;
			align-items: center;

			li {
				position: relative;

				a, button {
					height: 60px;
					width: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					background: none;
					position: relative;
					outline: none;

					&.activator {
						transition: all 0.3s;

						&:hover {
							background: @color2;

							svg {
								fill: #232323;
							}
						}
					}

					&.submitter {
						position: absolute;
						top: 0;
						visibility: hidden;
						opacity: 0;
						background: @color2;
						transition: all 0.3s;

						svg {
							fill: #232323;
						}

						&.active {
							visibility: visible;
							opacity: 1;
						}
					}

					svg {
						fill: #fff;
						transition: all 0.3s;

						&.icon-mobile {
							position: absolute;
							display: none;
						}

						&.icon-size-1 {
							height: 22px;
						}

						&.icon-size-2 {
							height: 30px;
						}
					}

					.quantity {
						position: absolute;
						bottom: 6px;
						right: 10px;
						padding-top: 1px;
						padding-left: 1px;
						background: #fff;
						height: 18px;
						width: 18px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						color: #232323;
						font-size: 10px;
						font-weight: 600;
					}
				}

				&.active {
					a {
						background: @color2;
						transition: background 0.3s;

						svg {
							fill: #232323;
						}

						&.white {
							background: #232323;

							svg {
								fill: #fff;
							}
						}
					}
				}
			}
		}

		.search-engine-wrapper {
			position: absolute;
			height: 100%;
			width: 960px;
			top: -100%;
			right: 180px;
			opacity: 0;
			background: #232323;
			display: flex;
			transition: all 0.3s;

			&.active {
				top: 0;
				opacity: 1;
				z-index: 333;
			}

			input {
				border: none;
				border-radius: 0;
				outline: none;
				font-family: 'gilroylight';
				background: #232323;
				color: #fff;
				width: 100%;
				height: 100%;
				display: block !important;
				text-transform: uppercase;
			}

			input::placeholder {
				color: #fff;
				text-transform: uppercase;
				font-family: 'gilroylight';
			}

			.search-engine-btn-close {
				min-width: 60px;
				height: 60px;
				background: #fff;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				outline: none;

				img {
					height: 17px !important;
				}
			}
		}

		.nav-button {
			padding: 0;
			height: 60px;
			width: 60px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			outline: none;
			position: relative;
			display: none;
			
			.button-bar {
				height: 2px;
				width: 24px;
				background: #fff;
				border-radius: 10px;
				margin-top: 4px;
				transition: all 0.3s;

				&:first-child {
					margin-top: 0;
				}
			}

			&:hover, &:focus {
				.button-bar {
					width: 28px;
				}
			}
		}
	}

	.visibility-hidden {
		opacity: 0;
	}
}

div.section-main-carousel {
	overflow: hidden;

	.item-flexbox {
		display: flex;
		align-items: center;

		.item-image {
			width: 50%;
			padding-left: 30px;

			img {
				width: 100%;
			}
		}

		.item-description {
			width: 50%;
			padding-left: 50px;

			h1, h2 {
				font-size: 65px;
				color: #232323;
				font-weight: 800;
				line-height: 64px;
				font-family: 'gilroyextrabold';
				padding-right: 50px;
			}

			p {
				padding-right: 100px;
				font-size: 14px;
				color: #414141;
			}

			.btn {
				margin-top: 25px;
			}
		}
	}

	.owl-carousel-slider {
		position: relative;

		.owl-stage-outer {
			z-index: 222;
			position: relative;
		}

		.owl-controls {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			display: flex;
			align-items: center;

			.owl-nav {
				width: 100%;
				max-width: 1180px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.owl-prev {
					margin: 0;
					background: none;
					padding: 0;
					position: relative;
					z-index: 333;
					opacity: 1;
					transition: all 0.4s;

					&:hover {
						opacity: 1;
					}

					&:before {
						content: "";
						width: 60px;
						height: 50px;
						background: url('../img/pictures/left.svg') center;
						background-size: cover;
						display: block;
					}
				}

				.owl-next {
					margin: 0;
					background: none;
					padding: 0;
					position: relative;
					z-index: 333;
					opacity: 1;
					transition: all 0.4s;

					&:hover {
						opacity: 1;
					}

					&:before {
						content: "";
						width: 60px;
						height: 50px;
						background: url('../img/pictures/right.svg') center;
						background-size: cover;
						display: block;
					}
				}
			}
		}
	}
}

div.section-offer {
	padding: 40px 0;

	.heading {
		text-align: center;
		color: #414141;
		font-size: 26px;
		line-height: 26px;
		font-weight: 300;
	}

	.owl-carousel-wrapper {
		padding: 0 40px;
		position: relative;

		.products-flexbox {
			.product-service {
				width: 100%;
			}
		}

		.owl-carousel {
			.owl-stage-outer {
				z-index: 222;
				position: relative;
			}
			
			.owl-item {
				padding: 13px;

				img {
					display: block !important;
					transform: none;
					transform-style: flat !important;
				}
			}
		
			.owl-controls {
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				display: flex;
				align-items: center;
		
				.owl-nav {
					width: 100%;
					max-width: 1140px;
					margin: 20px auto 0 auto;
					display: flex;
					justify-content: space-between;
		
					.owl-prev {
						margin: 0;
						background: none;
						padding: 0;
						position: relative;
						z-index: 333;
						margin-left: -40px;
		
						&:before {
							content: "";
							width: 35px;
							height: 35px;
							background: url('../img/pictures/prev-small-dark.svg') center;
							background-size: cover;
							display: block;
							transition: all 0.3s;
						}

						&:hover {
							&:before {
								transform: scale(1.1);
							}
						}
					}
		
					.owl-next {
						margin: 0;
						background: none;
						padding: 0;
						position: relative;
						z-index: 333;
						margin-right: -40px;
		
						&:before {
							content: "";
							width: 35px;
							height: 35px;
							background: url('../img/pictures/next-small-dark.svg') center;
							background-size: cover;
							display: block;
							transition: all 0.3s;
						}

						&:hover {
							&:before {
								transform: scale(1.1);
							}
						}
					}
				}
		
				.owl-dots {
					display: none;
				}
			}
		}
	}

	.products-flexbox {
		margin-top: 20px;

		.product-service {
			margin-top: 0;

			& + .product-service {
				margin-top: 20px;
			}

			&:hover {
				background: #fff;
				border-color: #fff;
				box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
			}
		}

		&.center {
			justify-content: center;
			padding: 0 40px;

			.product-service-wrapper {
				width: 25%;
				padding: 13px;
			}
		}

		&.hidden-desktop {
			display: none;
		}
	}

	&.promotion {
		padding: 0;

		.offer-inner {
			padding: 10px 0 40px 0;
			position: relative;

			.inner-cover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url(../img/backgrounds/pattern_gouda_works.svg);
				background-size: 20%;
				opacity: 0.05;
			}
		}

		.owl-carousel-wrapper {
			.owl-carousel {
				.owl-controls {
					.owl-nav {
						.owl-prev {
							&:before {
								background: url('../img/pictures/prev-small-light.svg') center;
								background-size: cover;
							}
						}
			
						.owl-next {
							&:before {
								background: url('../img/pictures/next-small-light.svg') center;
								background-size: cover;
							}
						}
					}
				}
			}
		}

		.products-flexbox {
			.product-service {
				box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);

				&:hover {
					box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}

div.kategoria-section-content {
	margin-bottom: 50px;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-sidebar {
			margin-top: 50px;
			width: 240px;

			.nav-button {
				outline: none;
				padding: 10px;
				width: 100%;
				border: none;
				font-size: 11px;
				color: #fff;
				text-transform: uppercase;
				background: #767676;
				color: #fff;
				transition: all 0.3s;
				display: none;

				&:hover {
					background: @color2;
					color: #414141;
				}
			}

			.category-nav-wrapper {
				.nav-button-close {
					position: absolute;
					display: none;
					width: 100%;
					top: 20px;
					left: 0;

					.container {
						display: flex;
						justify-content: flex-end;

						&:before, &:after {
							content: none;
						}
					}

					button {
						outline: none;
						border: none;
						background: none;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;

						.button-bar {
							display: block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #222;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				.category-nav-inner {
					.category-nav {
						li {
							list-style: none;

							a {
								color: #414141;
								font-size: 32px;
								font-weight: 300;
								line-height: 33px;
							}

							&.show-all {
								margin-top: 10px;

								a {
									display: block;
									font-size: 13px;
									font-weight: 300;
									text-transform: uppercase;
									color: #414141;
									border-top: 1px solid #e0e1dc;
									padding: 3px 0;
								}

								&.active {
									a {
										font-weight: 700;
									}
								}
							}

							&.child {
								ul {
									margin-bottom: 30px;

									li {
										margin-top: 0;

										a {
											font-size: 13px;
											font-weight: 300;
											text-transform: uppercase;
											color: #414141;
											border-bottom: 1px solid #e0e1dc;
											display: flex;
											align-items: center;
											justify-content: space-between;
											padding: 3px 0;
										}

										&.active {
											a {
												font-weight: 700;
											}
										}

										&:first-child {
											a {
												border-top: 1px solid #e0e1dc;
											}
										}
									}
								}
							}
						}
					}

					.filter-nav {
						margin-top: 50px;

						.heading {
							color: #414141;
							font-size: 20px;
							font-weight: 300;
						}

						.small-heading {
							color: #414141;
							font-weight: 700;
							margin-bottom: 15px;
						}

						ul {
							li {
								.checkbox-wrapper {
									margin-top: 7px;

									.radio-box {
										align-items: center;

										p {
											font-size: 13px;
											color: #414141;
											font-weight: 300;
											transition: all 0.3s;
										}

										&:before {
											min-width: 14px;
											max-width: 14px;
											height: 14px;
											background: url('../img/pictures/checkbox-icon.svg') center no-repeat;
											background-size: cover;
										}
									}
								}

								.checkbox-wrapper > input:checked + .radio-box {
									p {
										font-weight: 700;
									}

									&:before {
										background: url('../img/pictures/checkbox-checked-icon.svg') center no-repeat;
										background-size: cover;
									}
								}
							}
						}

						.inputs-wrapper {
							display: flex;
							align-items: center;
							justify-content: space-between;

							input {
								width: 100%;
								height: 30px;
								background: #e0e1dc;
								border: none;
								text-align: center;
								padding: 0 5px;
							}

							input:placeholder {
								color: #414141;
								font-size: 13px;
								font-weight: 300;
							}

							span {
								padding: 0 20px;
							}

							input[type=number]::-webkit-inner-spin-button, 
							input[type=number]::-webkit-outer-spin-button { 
								-moz-appearance: none;
								-webkit-appearance: none;
								appearance: none;
								margin: 0; 
							}

							input[type=number] {
								-moz-appearance:textfield;
							}
						}

						.buttons-wrapper {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.btn-clear {
								color: #414141;
								text-transform: uppercase;
								border: none;
								background: none;
								padding: 0;
							}
						}

						.category-description {
							p {
								color: #7a7a7a;
							}
						}

						> * {
							padding-top: 15px;
							margin-top: 15px;
							border-top: 1px solid #e0e1dc;

							&:first-child {
								padding: 0;
								margin: 0;
								border: none;
							}
						}
					}
				}
			}
		}

		.flexbox-body {
			margin-top: 19.5px;
			width: 800px;

			.heading {
				color: #414141;
				font-size: 32px;
				font-weight: 300;
				line-height: 33px;
				max-width: 240px;
				visibility: hidden;
				opacity: 0;
				z-index: -1;
			}

			.sorting-flexbox-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #e0e1dc;

				.sorting-flexbox {
					margin-top: 15px;
					display: flex;
					align-items: center;

					li {
						margin-right: 10px;
						padding-bottom: 5px;

						p {
							font-size: 13px;
							text-transform: uppercase;
							font-weight: 700;
							color: #414141;
						}
						
						a {
							font-size: 13px;
							text-transform: uppercase;
							font-weight: 300;
							color: #414141;

							img {
								height: 15px;
								margin-right: 5px;
							}
						}

						&.active {
							a {
								font-weight: 700;
							}
						}

						&.divider {
							background: #e0e1dc;
							height: 25px;
							width: 1px;
							align-self: flex-end;
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.grid-flexbox {
					display: flex;
					align-items: center;
					
					li {
						margin-right: 10px;
						opacity: 0.5;

						a {
							img {
								height: 14px;
							}
						}

						&.active {
							opacity: 1;
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}

			.products-flexbox {
				.product-service {
					width: 24%;
				}
			}

			.pagination-flexbox {
				margin-top: 20px;
				border-top: 1px solid #e0e1dc;
				display: flex;
				justify-content: space-between;

				.score {
					display: flex;

					p {
						padding-top: 4px;
						font-size: 13px;
						text-transform: uppercase;
						font-weight: 700;
						color: #414141;
						margin-right: 10px;
						padding-right: 10px;
						border-right: 1px solid #e0e1dc;
						display: flex;
						align-items: center;

						img {
							height: 13px;
							margin-right: 5px;
						}

						&:last-child {
							margin-right: 0;
							padding-right: 0;
							border: none;
						}
					}
				}

				ul {
					display: flex;

					li {
						padding-top: 4px;
						margin-right: 10px;
						padding-right: 10px;
						border-right: 1px solid #e0e1dc;

						a {
							font-size: 13px;
							text-transform: uppercase;
							font-weight: 700;
							color: #414141;
						}

						&.disabled {
							opacity: 0.4;

							a {
								cursor: default;
							}
						}

						&:last-child {
							margin-right: 0;
							padding-right: 0;
							border: none;
						}
					}
				}
			}
		}
	}

	&.wyszukiwanie {
		.content-flexbox {
			.flexbox-body {
				.products-flexbox {
					.product-service {
						width: 18%;
					}
				}
			}
		}
	}
}

div.produkt-section-content {
	padding-bottom: 50px;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-sidebar {
			margin-top: 50px;
			padding-bottom: 20px;
			width: 48%;

			.owl-carousel-slider {
				position: relative;

				.owl-stage-outer {
					z-index: 222;
					position: relative;
				}

				.owl-controls {
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					display: flex;
					align-items: center;

					.owl-dots {
						display: flex;
						justify-content: center;
						position: absolute;
						bottom: -40px;
						width: 100%;
						left: 0;

						.owl-dot {
							min-height: 19px;
							min-width: 19px;
							max-width: 19px;
							display: flex;
							justify-content: center;
							align-items: center;

							span {
								background-color: #dedddd;
								min-width: 8px;
								max-width: 8px;
								height: 8px;
								border-radius: 50%;
								margin: 0;
								transition: all 0.3s;
							}

							&.active {
								span {
									background-color: #414141;
								}
							}
						}
					}

					.owl-nav {
						width: 100%;
						margin: 0 auto;
						display: flex;
						justify-content: space-between;

						.owl-prev {
							margin: 0;
							background: none;
							padding: 0;
							position: relative;
							z-index: 333;
							opacity: 1;
							transition: all 0.4s;

							&:hover {
								opacity: 1;
							}

							&:before {
								content: "";
								width: 60px;
								height: 50px;
								background: url('../img/pictures/left.svg') center;
								background-size: cover;
								display: block;
							}
						}

						.owl-next {
							margin: 0;
							background: none;
							padding: 0;
							position: relative;
							z-index: 333;
							opacity: 1;
							transition: all 0.4s;

							&:hover {
								opacity: 1;
							}

							&:before {
								content: "";
								width: 60px;
								height: 50px;
								background: url('../img/pictures/right.svg') center;
								background-size: cover;
								display: block;
							}
						}
					}
				}
			}

			img {
				width: 100%;
			}

			.lSAction {
				.lSPrev {
					width: 29px;
					height: 54px;
					background-image: url('../lib/lightslider-master/dist/img/prev.svg');
					background-size: cover;
					background-position: center;
					margin-top: 0px;
					transform: translateY(-50%);
					left: 20px;
				}

				.lSNext {
					width: 29px;
					height: 54px;
					background-image: url('../lib/lightslider-master/dist/img/next.svg');
					background-size: cover;
					background-position: center;
					margin-top: 0px;
					transform: translateY(-50%);
					right: 20px;
				}
			}

			.lSGallery {
				margin-top: 23px !important;

				li {
					border: 1px solid transparent;
					transition: all 0.3s;

					&.active, &:hover {
						border-radius: 0 !important;
						border: 1px solid #414141;
						padding: 3px;
					}
				}
			}
		}

		.flexbox-body {
			margin-top: 50px;
			width: 48%;

			.small-heading {
				font-size: 13px;
				font-weight: 300;
				color: #414141;
				text-transform: uppercase;
			}

			.price {
				margin-top: 20px;
				font-size: 22px;
				margin-bottom: 10px;
				font-weight: 600;
				color: #414141;

				span {
					font-weight: 400;
					margin-left: 15px;
					text-decoration: line-through;
				}
			}

			.name {
				font-size: 32px;
				font-weight: 300;
				color: #313131;
				font-family: 'gilroyextrabold';
			}

			.description {
				margin: 20px 0;
				padding: 20px 0;
				border-top: 1px solid #e0e1dc;
				border-bottom: 1px solid #e0e1dc;

				p {
					color: #414141;
					font-size: 14px;
					line-height: 22px;

					a {
						color: inherit;
						font-weight: 800;
						text-decoration: underline !important;
					}
				}
			}

			.sizes {
				margin: 10px 0 20px 0;
				display: flex;
				flex-wrap: wrap;

				label {
					margin-right: 5px;
					margin-top: 5px;
					margin-bottom: 0;

					.size-box {
						height: 40px;
						min-width: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: center;
						font-size: 13px;
						font-weight: 300;
						color: #414141;
						text-transform: uppercase;
						transition: all 0.3s;
					}
				}
				
				label > input {
					visibility: hidden;
					position: absolute;
				}
				
				label > input + .size-box {
					cursor: pointer;
					border: 1px solid transparent;

					&:hover {
						border: 1px solid #414141;
					}
				}

				label > input:disabled + .size-box {
					opacity: 0.3;
					cursor: default;

					&:hover {
						border: 1px solid transparent;
					}
				}
				
				label > input:checked + .size-box {
					border: 1px solid #414141;
				}
			}

			.btn {
				&.disabled {
					opacity: 0.4;
					position: relative;

					&:before {
						content: "Wybierz rozmiar";
						font-size: 10px;
						color: #000;
						opacity: 0;
						position: absolute;
						top: 50%;
						right: -110px;
						transform: translateY(-50%);
						transition: all 0.3s;
					}

					&:hover, &:focus {
						background: @color2;
						opacity: 0.6;

						&:before {
							opacity: 1;
						}
					}
				}
			}

			.panel-group {
				margin-top: 20px;

				.panel {
					border: none;
					background: none;
					box-shadow: none;
					margin: 0;

					.panel-heading {
						background: none;
						padding: 0;
						border-radius: 0;

						h4 {
							a {
								border-top: 1px solid #e0e1dc;
								padding: 5px 0;
								display: flex;
								justify-content: space-between;
								align-items: center;
								position: relative;

								.name {
									font-size: 13px;
									font-weight: 300;
									color: #414141;
									text-transform: uppercase;
									font-family: 'Roboto', sans-serif;
								}

								.name-toggle {
									&:before, &:after {
										content: "";
										position: absolute;
										right: 0;
										width: 7px;
										height: 1px;
										background: #414141;
										transition: all 0.3s;
									}

									&:after {
										transform: rotate(90deg);
									}
								}
							}
						}

						&.actived {
							h4 {
								a {
									.name-toggle {
										&:after {
											transform: rotate(0deg);
										}
									}
								}
							}
						}
					}

					.panel-body {
						border: none;
						padding: 0 0 5px 0;

						p {
							font-size: 14px;
							color: #414141;
						}

						img {
							width: 80%;
						}
					}
				}
			}
		}
	}
}

div.static-page-section-content {
	padding: 50px 0;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-description {
			width: 45%;

			.small-heading {
				font-size: 13px;
				font-weight: 300;
				color: #414141;
				text-transform: uppercase;
				display: none;
			}

			.heading {
				margin-bottom: 20px;
				font-weight: 300;
				color: #414141;
				font-size: 28px;
			}

			p {
				color: #414141;
			}
		}

		.flexbox-image {
			width: 45%;

			img {
				width: 100%;
			}
		}
	}
}

div.kontakt-section-content {
	padding-top: 50px;

	.small-heading {
		font-size: 13px;
		font-weight: 300;
		color: #414141;
		text-transform: uppercase;
	}

	.heading {
		margin-top: 20px;
		font-weight: 300;
		color: #414141;
		font-size: 28px;
	}

	.brand-flexbox {
		margin-top: 20px;
		display: flex;
		align-items: center;

		img {
			height: 75px;
			margin-right: 20px;
		}

		.flexbox-description {
			p {
				font-size: 16px;
				text-transform: uppercase;
				color: #414141;
			}
		} 
	}

	address {
		margin-top: 20px;
		margin-bottom: 0;
		font-size: 13px;
		color: #414141;

		a {
			color: #414141;
		}
	}

	.google-map {
		margin-top: 100px;
		width: 100%;
		height: 330px;
		border: none;

		&.full-height {
			margin-top: 0;
			height: 100vh - 60px;
		}
	}
}

.checkbox-wrapper {
	cursor: pointer;
	display: block;

	.radio-box {
		display: flex;
		align-items: center;
		font-size: 16px;
		text-transform: none;
		color: #414141;
		font-weight: 300;
		text-align: left;
		transition: all 0.3s;

		&:before {
			content: '';
			min-width: 20px;
			max-width: 20px;
			height: 20px;
			display: block;
			background: url('../img/pictures/checkbox-icon.svg') no-repeat;
			margin-right: 10px;
			transition: all 0.3s;
		}
	}

	input {
		visibility: hidden;
		position: absolute;
		width: auto !important;
	}

	&.background-red {
		.radio-box {
			color: #d4213d;

			a {
				color: darken(#d4213d, 10%);
			}
		}
	}
}

.checkbox-wrapper > input:checked + .radio-box {
	font-weight: 700;

	&:before {
		background: url('../img/pictures/checkbox-checked-icon.svg') no-repeat;
	}
}

div.panel-klienta-logowanie-section-content {
	padding: 100px 0;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			width: 50%;
			border-right: 1px solid #e0e1dc;
			text-align: center;

			img {
				max-width: 100%;
				width: 100px;
			}

			.heading {
				margin: 70px 0 20px 0;
				font-weight: 300;
				color: #414141;
				font-size: 28px;
			}

			input {
				margin: 10px auto 0 auto;
				background: #e0e1dc;
				border: none;
				display: block;
				height: 40px;
				width: 250px;
				max-width: 100%;
				padding: 10px;
				outline: 0;
				text-align: center;
			}

			input::placeholder {
				text-transform: uppercase;
				color: #414141;
				font-size: 13px;
				text-align: center;
			}

			.checkbox-wrapper {
				margin-top: 30px;
				padding: 0 50px;

				.radio-box {
					justify-content: center;
					align-items: center;
					font-size: 13px;

					a {
						color: @color2;
					}

					&:before {
						min-width: 14px;
						max-width: 14px;
						height: 14px;
						background: url('../img/pictures/checkbox-icon.svg') center no-repeat;
						background-size: cover;
					}
				}
			}

			.checkbox-wrapper > input:checked + .radio-box {
				font-weight: 300;

				&:before {
					background: url('../img/pictures/checkbox-checked-icon.svg') center no-repeat;
					background-size: cover;
				}
			}

			.recaptcha-wrapper {
				margin-top: 20px;
				display: flex;
				justify-content: center;
			}

			.btn {
				margin-top: 20px;
			}

			.btn-link {
				margin-top: 20px;
				font-size: 13px;
				font-weight: 300;
				color: #414141;
				display: block;
			}

			&:last-child {
				border-right: none;
			}
		}
	}

	.password-wrapper {
		margin: 0 auto;
		width: 100%;
		max-width: 300px;
		text-align: center;

		.heading {
			margin: 30px 0 20px 0;
			font-weight: 300;
			color: #414141;
			font-size: 28px;
		}

		label {
			font-size: 11px;
			color: #414141;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		input {
			background: #e0e1dc;
			border: none;
			display: block;
			height: 40px;
			width: 100%;
			padding: 10px;
			outline: 0;
		}

		.buttons-wrapper {
			margin-top: 20px;
			text-align: center;

			.btn-cancel {
				display: inline-block;
				padding: 20px;
				font-size: 13px;
				color: #767676;
				text-transform: uppercase;
			}
		}
	}
}

div.panel-klienta-section-content {
	padding: 50px 0 100px 0;

	.heading {
		font-weight: 300;
		color: #414141;
		font-size: 28px;
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			width: calc(100% / 3);
			padding: 0 20px;
			border-right: 1px solid #e0e1dc;
			text-align: center;

			img {
				width: 80%;
				max-width: 75px;
			}

			.heading {
				margin: 60px 0 20px 0;
			}

			ul {
				li {
					margin-bottom: 5px;

					a {
						color: #414141;
						font-weight: 700;

						span {
							font-weight: 400;
						}

						&:hover {
							text-decoration: underline !important;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			&:last-child {
				border-right: none;
			}
		}
	}

	.steps-flexbox {
		.flexbox-service {
			cursor: pointer;
		}
	}

	.password-wrapper {
		margin: 50px auto 0 auto;
		width: 100%;
		max-width: 300px;
		text-align: center;

		label {
			font-size: 11px;
			color: #414141;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		input {
			background: #e0e1dc;
			border: none;
			display: block;
			height: 40px;
			width: 100%;
			padding: 10px;
			outline: 0;
			text-align: center;
		}
	}

	.buttons-wrapper {
		margin-top: 50px;
		text-align: center;

		.btn-cancel {
			display: inline-block;
			padding: 20px;
			font-size: 13px;
			color: #767676;
			text-transform: uppercase;
		}
	}

	.order-info {
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.info-description {
			.heading {
				font-weight: 300;
				color: #414141;
				font-size: 22px;
			}

			.date {
				font-size: 16px;
				font-weight: 300;
				color: #999;
			}
		}
	}
}

div.panel-klienta-koszyk-section-content {
	padding: 50px 0;

	.disabled {
		pointer-events: none;
	}

	.heading-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		& + .shipping-types {
			margin-top: 0;
		}
	}

	.heading {
		font-weight: 300;
		color: #414141;
		font-size: 28px;
	}

	.empty-alert {
		text-align: center;
		margin: 100px 0;

		p {
			font-weight: 300;
			color: #414141;
			font-size: 28px;
		}
	}

	.confirm-buttons {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.login-flexbox {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.flexbox-service {
			width: 50%;
			border-left: 1px solid #e0e1dc;
			text-align: center;

			img {
				max-width: 100%;
				width: 100px;
			}

			.heading {
				margin-top: 70px;
				margin-bottom: 20px;
				font-weight: 300;
				color: #414141;
				font-size: 28px;
			}

			.text {
				padding: 0 50px;
				font-size: 13px;
				font-weight: 300;
				color: #414141;
			}

			input {
				margin: 10px auto 0 auto;
				background: #e0e1dc;
				border: none;
				display: block;
				height: 40px;
				width: 250px;
				padding: 10px;
				outline: 0;
				text-align: center;
			}

			input::placeholder {
				text-transform: uppercase;
				color: #414141;
				font-size: 13px;
				text-align: center;
			}

			.btn {
				margin-top: 20px;
			}

			.btn-light {
				margin-bottom: 50px;
			}

			.btn-link {
				margin-top: 20px;
				font-weight: 300;
				font-size: 13px;
				color: #414141;
				display: block;
			}

			&:first-child {
				border-left: none;
			}
		}
	}

	.free-delivery-wrapper {
		margin-top: 50px;
		min-width: 520px;

		.wrapper-progress {
			margin-bottom: 6px;

			.progress-line {
				width: 100%;
				height: 6px;
				background: #ECECEC;

				.line-inner {
					background: #A76C68;
					height: 100%;
				}
			}
		}

		.wrapper-inner {
			background: #A76C68;
			width: 100%;
			display:flex;
			align-items: center;
			justify-content: center;
			padding: 8px 15px;

			.inner-icon {
				img {
					height: 42px;
					width: 42px;
				}
			}

			.inner-heading {
				margin-left: 12px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				img {
					margin: 0 20px 2px 20px;
					height: 12px;
					animation: moveArrow 0.6s infinite ease-in-out;
				}

				@keyframes moveArrow {
					0% {
						transform: translateX(-3px);
					}
					50% {
						transform: translateX(3px);
					}
					100% {
						transform: translateX(-3px);
					}
				}

				p {
					font-size: 12px;
					color: #fff;
					text-transform: uppercase;

					b {
						font-weight: 700;
					}
				}
			}
		}

		&.success {
			.wrapper-progress {
				opacity: 0;
			}

			.wrapper-inner {
				background: @color2;
			}
		}
	}

	.easypack-widget .search-widget .search-group:not(.with-filters) #searchLoader.hidden {
		display: none !important;
	}

	.easypack-widget {
		margin-top: 50px;

		.search-widget {
			.search-group {
				padding: 0;

				input {
					padding: 15px 15px 15px 20px;
					border: 1px solid #ddd;
					border-right: none;
					border-radius: 0;
					outline: none;
					font-family: 'gilroylight';
					background: #fff;
					color: #232323;
					width: 100%;
					height: 60px;
					display: block !important;
					text-transform: uppercase;
				}

				input::placeholder {
					color: #232323;
					text-transform: uppercase;
					font-family: 'gilroylight';
				}

				.loading-icon-wrapper {
					display: none !important;
				}
			}

			.btn {
				border: none;
				background: @color2;
				background-image: none !important;
				position: relative;
				transition: all 0.4s;

				&:after {
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg class='icon-size-1 icon-desktop' version='1.1' id='Warstwa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 42.8 44.8' style='enable-background:new 0 0 42.8 44.8;' xml:space='preserve'%3E%3Cpath fill='%23434343' d='M2,44.8c-0.5,0-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8l12-12C9.4,26,7.8,22,7.8,17.4c0-4.7,1.7-8.9,5.2-12.3C16.4,1.7,20.5,0,25.3,0c4.8,0,8.9,1.7,12.3,5.1c3.5,3.6,5.2,7.7,5.2,12.4c0,4.8-1.8,9-5.2,12.3c-3.4,3.4-7.5,5.1-12.3,5.1c-3.6,0-6.8-1-9.6-2.9L3.4,44.2C3,44.6,2.5,44.8,2,44.8z M25.3,4c-3.7,0-6.9,1.3-9.5,3.9c-2.7,2.7-4,5.8-4,9.5c0,3.7,1.3,6.8,4,9.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c2.6,2.2,5.5,3.3,8.8,3.3c3.7,0,6.9-1.3,9.5-3.9c2.7-2.6,4-5.7,4-9.5c0-3.7-1.3-6.8-4-9.6C32.2,5.3,29,4,25.3,4z M16,20.2c-1.1,0-2-0.9-2-2c0-3.2,1.1-5.9,3.4-8c2.1-2.2,4.9-3.4,8.1-3.4h0.4c1.1,0,2,0.9,2,2s-0.9,2-2,2h-0.4c-2.1,0-3.9,0.7-5.2,2.2c0,0,0,0.1-0.1,0.1c-1.5,1.4-2.2,3.1-2.2,5.1C18,19.3,17.1,20.2,16,20.2z'/%3E%3C/svg%3E");
					background-size: cover;
					height: 24px;
					width: 23.5px;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				&:hover {
					background: darken(@color2, 10%);
				}

				&:active {
					box-shadow: none !important;
				}

				&:focus {
					outline: none !important;
				}
			}

			.inpost-search__list {
				top: 60px;
				left: 0;
			}
		}
	}
}

.steps-flexbox {
	margin-top: 50px;
	display: flex;

	.flexbox-service {
		width: calc(100% / 3);
		padding: 10px 5px;
		text-align: center;
		border-right: 1px solid #e0e1dc;
		border-bottom: 1px solid #e0e1dc;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: default;

		.name {
			font-size: 20px;
			color: #414141;
			font-weight: 300;
			opacity: 0.5;
		}

		.text {
			font-size: 13px;
			color: #414141;
			text-transform: uppercase;
			font-weight: 700;
			opacity: 0.5;
		}

		&.active {
			border-bottom: none;

			.name, .text {
				opacity: 1;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: -40px;
				height: 100%;
				width: 40px;
				opacity: 0.05;
				background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
				background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
				background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: -40px;
				height: 100%;
				width: 40px;
				opacity: 0.05;
				background: -moz-linear-gradient(left, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
				background: -webkit-linear-gradient(left, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				background: linear-gradient(to right, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
			}
		}

		&:nth-child(1) {
			&.active {
				&:before {
					content: none;
				}
			}
		}

		&:nth-child(2) {

		}

		&:nth-child(3) {
			border-right: none;

			&.active {
				&:after {
					content: none;
				}
			}
		}
	}
}

.address-flexbox {
	margin-top: 50px;
	display: flex;
	justify-content: space-between;

	.flexbox-service {
		width: 50%;
		border-left: 1px solid #e0e1dc;

		.heading {
			margin-bottom: 30px;
			font-weight: 300;
			color: #414141;
			font-size: 22px;
		}

		label {
			font-size: 11px;
			color: #414141;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		input {
			background: #e0e1dc;
			border: none;
			display: block;
			height: 40px;
			width: 100%;
			padding: 10px;
			outline: 0;
		}

		input::placeholder {
			text-transform: uppercase;
			color: #414141;
			font-size: 13px;
			text-align: center;
		}

		textarea {
			background: #e0e1dc;
			border: none;
			display: block;
			height: 140px;
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			padding: 10px;
			outline: 0;
		}

		.checkbox-wrapper {
			margin-top: 30px;
		}

		.checkbox-wrapper > input:checked + .radio-box {
			font-weight: 300;
		}

		.btn-delete {
			margin-top: 30px;
			padding: 0;
			background: none;
			display: flex;
			align-items: center;
			border: none;
			font-size: 16px;
			text-transform: none;
			color: #414141;
			font-weight: 300;
			opacity: 0.5;

			img {
				margin-right: 10px;
			}
		}

		&:nth-child(1) {
			padding-right: 50px;
			border-left: none;
		}

		&:nth-child(2) {
			padding-left: 50px;
		}
	}

	&.inputs-white {
		.flexbox-service {
			input {
				background: #fff;
				padding-left: 0;
				height: 20px;
				font-size: 16px;
			}

			.textarea {
				margin-top: 5px;
				font-size: 14px;

				&:before, &:after {
					content: '"';
				}
			}
		}
	}
}

.shipping-types {
	margin-top: 20px;
	padding-top: 10px;
	border-top: 1px solid #e0e1dc;

	.checkbox-wrapper {
		margin-top: 10px;

		.wrapper-inner {
			display: flex;

			img {
				height: 42px;
				margin-right: 10px;
				opacity: 0.5;
				transition: all 0.4s;
			}

			.radio-box {
				opacity: 0.7;
			}
		}
	}

	.checkbox-wrapper > input:checked + .wrapper-inner {
		img {
			opacity: 1;
		}

		.radio-box {
			opacity: 1;
			font-weight: 700;

			&:before {
				background: url('../img/pictures/checkbox-checked-icon.svg') center no-repeat;
				background-size: cover;
			}
		}
	}
}

.payments-credits {
	margin-top: 30px;
	padding-top: 30px;
	border-top: 1px solid #e0e1dc;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.credits-service {
		display: flex;
		align-items: center;

		p {
			font-size: 12px;
			font-weight: 700;
			color: #767676;
			text-transform: uppercase;
			white-space: nowrap;

			& + .service-box {
				margin-left: 10px;
			}
		}

		.p24 {
			margin-left: 20px;
			height: 25px;

			& + img {
				margin-left: 20px;
			}
		}

		.service-box {
			display: flex;
			align-items: center;

			.box-image {
				height: 29px;
				margin-right: 9px;
			}

			span {
				font-size: 10px;
				color: #414141;
				font-weight: 300;
				display: block;
				line-height: 11.5px;
				text-transform: uppercase;
				max-width: 100px;
			}

			& + .service-box {
				margin-left: 40px;
			}
		}
	}
}

.inpost-select {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #e0e1dc;
	display: flex;
	align-items: center;

	input {
		background: #e0e1dc;
		border: none;
		display: block;
		height: 46px;
		width: 100%;
		max-width: 100%;
		padding: 10px;
		outline: 0;
		text-transform: uppercase;
	}

	input::placeholder {
		text-transform: uppercase;
		color: #414141;
		font-size: 13px;
		text-align: center;
	}
}

.product-extra {
	margin-top: 30px;
	display: flex;
	align-items: center;

	.extra-image {
		width: 200px;
		margin-right: 50px;

		img {
			width: 100%;
		}
	}

	.extra-description {
		margin-top: 20px;

		h3 {
			font-weight: 300;
			color: #414141;
			font-size: 22px;
		}

		p {
			font-size: 16px;
			margin-top: 17px;
			font-weight: 300;
		}

		.btn {
			margin-top: 20px;
		}
	}
}

.price-summary {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;

	.summary-inner {
		&:nth-child(2) {
			text-align: right;
		}
	}

	.small-heading {
		font-size: 11px;
		color: #414141;
		font-weight: 700;
		text-transform: uppercase;
	}

	.number {
		margin-top: 8px;
		font-weight: 300;
		font-size: 36px;
		color: #414141;

		span {
			color: #999;
			position: relative;
			display: inline-block;
			margin-right: 15px;

			&:after {
				content: '';
				width: 100%;
				position: absolute;
				height: 1px;
				background: #999;
				top: 50%;
				left: 0;
			}
		}
	}

	.summary-code {
		display: flex;
		align-items: center;
		justify-content: center;

		.code-inner {
			margin-top: 10px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			input {
				background: #e0e1dc;
				border: none;
				display: block;
				height: 46px;
				width: 200px;
				max-width: 100%;
				padding: 10px;
				outline: 0;
			}

			input::placeholder {
				text-transform: uppercase;
				color: #414141;
				font-size: 13px;
				text-align: center;
			}

			.inner-input {
				position: relative;

				button {
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%);
					padding: 0;
					border: none;
					background: none;
					outline: none;

					img {
						height: 15px;
						width: 15px;
					}
				}

				input {
					padding-right: 30px;
				}
			}

			.inner-value {
				height: 46px;
				background: @color2;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 12px;
				font-size: 15px;
				font-weight: 700;
				color: #fff;
			}

			.btn-default {
				height: 46px;
			}
		}

		.code-icon {
			margin-top: 10px;
			margin-bottom: 20px;
			min-width: 30px;
			max-width: 30px;
			margin-left: 20px;
		}

		.code-value {
			margin-top: 10px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			p {
				font-size: 14px;
				color: #414141;
				color: darken(@color2, 10%);
				font-weight: 700;

				&:nth-child(2) {
					margin-left: 20px;
				}
			}

			button {
				margin-left: 10px;
				padding: 0;
				border: none;
				background: none;
				outline: none;

				img {
					height: 15px;
					width: 15px;
				}
			}
		}
	}
}

.table-responsive {
	margin: 50px 0;
	border: none;

	.table {
		margin: 0;

		tr {
			th {
				font-size: 11px;
				color: #414141;
				font-weight: 700;
				text-transform: uppercase;
				padding: 0 5px 10px 5px;
				border: none;
				vertical-align: middle;
				text-align: center;

				&:first-child {
					text-align: left;
				}
			}

			td {
				padding: 10px 5px;
				border-top: 1px solid #e0e1dc;
				vertical-align: middle;
				text-align: center;

				.product {
					display: flex;
					align-items: center;

					img {
						width: 80px;
					}

					.product-description {
						margin-left: 15px;

						.name {
							font-size: 16px;
							color: #414141;
							font-weight: 700;
						}

						.type, .size {
							font-size: 11px;
							color: #414141;
							font-weight: 700;
							text-transform: uppercase;
						}
					}
				}

				button {
					padding: 0;
					border: none;
					background: none;
				}

				.price {
					font-size: 16px;
					color: #414141;
					font-weight: 700;

					span {
						color: #999;
						position: relative;
						display: inline-block;

						&:after {
							content: '';
							width: 100%;
							position: absolute;
							height: 1px;
							background: #999;
							top: 50%;
							left: 0;
						}
					}
				}

				.quantity {
					input {
						width: 30px;
						text-align: center;
						outline: none;
						border: none;
						font-size: 13px;
						font-weight: 700;
						color: #414141;
						background: none;
					}

					input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					button {
						outline: none;
						border: none;
						padding: 0;
						background: none;
					}
				}

				&:first-child {
					text-align: left;
				}
			}
		}
	}

	&.orders {
		margin-bottom: 0;

		.table {
			tr {
				td {
					padding: 30px 5px;
				}
			}
		}
	}
}

div.panel-klienta-koszyk-podsumowanie-section-content {
	padding: 50px 0 100px 0;

	.content-description {
		text-align: center;

		img {
			width: 100%;
			max-width: 280px;
		}

		.heading, h2 {		
			font-weight: 300;
			color: #414141;
			font-size: 28px;
			margin: 30px 0;
		}

		.spinner-box {
			margin-top: 50px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;

			.three-quarter-spinner {
				width: 50px;
				height: 50px;
				border: 3px solid #414141;
				border-top: 3px solid transparent;
				border-radius: 50%;
				animation: spin 1.5s linear 0s infinite;
			}

			@keyframes spin {
				from {
					transform: rotate(0);
				}
				to{
					transform: rotate(359deg);
				}
			}
		}

		hr {
			border-color: #dddddd;
		}

		p {
			color: #414141;
			margin-bottom: 10px;

			a {
				color: #414141;
			}
		}

		.btn {
			margin-top: 20px;
		}
	}
}

div.section-footer-wrapper {
	position: relative;
	background: #e0e1dc;

	.background-cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../img/backgrounds/pattern_gouda_works.svg);
		background-size: 32%;
		opacity: 0.05;
	}

	.footer-wrapper-heading {
		background: #fff;
		padding-bottom: 30px;
		position: relative;

		.heading {
			text-align: center;
			color: #414141;
			font-size: 26px;
			line-height: 26px;
			font-weight: 300;
		}
	}
}

div.section-footer {
	padding-top: 50px;
	background: #414141;

	.footer-flexbox {
		display: flex;
		justify-content: space-between;
		position: relative;

		.collapse-wrapper {
			display: none;
		}

		.flexbox-service {
			.heading {
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				color: #a6a6a6;
				margin-bottom: 10px;
			}

			.logo {
				height: 33px;
				margin-bottom: 25px;
			}

			ul {
				li {
					margin-bottom: 5px;

					a {
						font-size: 11px;
						text-transform: uppercase;
						color: #a6a6a6;
						border-bottom: 1px solid transparent;

						img {
							margin-right: 5px;
							height: 13px;
							position: relative;
							top: -2px;
						}

						&:hover {
							border-bottom: 1px solid #999;
						}
					}
				}
			}

			&.newsletter {
				display: none;

				.text {
					font-size: 11px;
					text-transform: uppercase;
					color: #a6a6a6;
					max-width: 260px;
				}

				input {
					margin-top: 10px;
					padding: 5px;
					border: none;
					border-radius: 0;
					width: 100%;
				}

				input::placeholder {
					font-size: 11px;
					text-transform: uppercase;
					color: #a6a6a6;
				}

				.btn {
					margin-top: 10px;
				}
			}
		}
	}

	.footer-credits {
		margin-top: 50px;
		background: #1c1c1c;
		padding: 20px 0 20px 0;
		position: relative;

		.credits-flexbox {
			display: flex;
			justify-content: space-between;

			.flexbox-inner {
				display: flex;
				justify-content: space-between;

				.inner-service {
					display: flex;
					justify-content: space-between;
					align-items: center;

					svg {
						fill: @color1;
						height: 8.5px;
						margin-left: 8px;
					}
				}

				> * + * {
					margin-left: 32px;
				}
			}

			p {
				font-family: 'gilroymedium';
				color: #e0e1dc;
				font-size: 11px;
				text-transform: uppercase;
				letter-spacing: 2.2px;
				font-weight: 800;

				span {
					letter-spacing: 0.3px;
				}

				strong {
					font-weight: 800;
					color: @color1;
				}

				a {
					color: #e0e1dc;
					padding-left: 5px;
				}
			}
		}
	}
}

.cookies-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.cookies-service {
		padding: 50px 0;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: rgba(0, 0, 0, 0.8);
			width: 100%;
			padding: 85px 0;
			text-align: center;
			position: relative;

			.inner-content {
				img {
					width: 70px;
				}

				p {
					margin: 25px 0;
					font-size: 17px;
					color: #fff;
					font-weight: 300;
				}
			}
		}
	}
}